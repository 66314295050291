<template>
  <div class="hero is-fullheight">
    <video class="video" autoplay muted loop>
      <source src="@/assets/video/login.mp4" type="video/mp4">
    </video>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <img class="logo" src="@/assets/images/logo.png" alt="Coopenaza">
            <div v-if="step === 0" class="card">
              <div class="card-content">
                <div class="form-types">
                  <h2 class="title has-text-centered">Você é?</h2>
                  <div class="field">
                    <button class="button is-link is-fullwidth" @click="toForm(userRules.ADMIN)">Administrador</button>
                  </div>
                  <div class="field">
                    <button class="button is-link is-fullwidth" @click="toForm(userRules.TEACHER)">Professor</button>
                  </div>
                  <div class="field">
                    <button class="button is-link is-fullwidth" @click="toForm(userRules.STUDENT)">Aluno</button>
                  </div>
                  <div class="field">
                    <button class="button is-link is-fullwidth" @click="toForm(userRules.RESPONSIBLE)">Responsável</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="step === 1" class="card">
              <div class="card-content">
                <button class="back-btn button is-link" @click="step = 0">
                  <i class="lni lni-arrow-left" />
                </button>
                <div class="block">
                  <figure class="avatar image is-128x128">
                    <img class="is-rounded" src="http://via.placeholder.com/128?text=Avatar">
                  </figure>
                </div>
                <form @submit.prevent="sendForm" class="form">
                  <div v-if="error" class="notification is-danger field">
                    <button class="delete" @click="error = null"></button>
                    {{ error }}
                  </div>
                  <!-- email -->
                  <div class="field">
                    <div class="control has-icons-left">
                      <input class="input" :type="rule === 'STUDENT' ? 'text' : 'email'" :placeholder="rule === 'STUDENT' ? 'Email ou Matricula' : 'Email'" v-model="email">
                      <span class="icon is-small is-left">
                        <i class="fi-xnluxl-envelope"></i>
                      </span>
                    </div>
                  </div>
                  <!-- password -->
                  <div class="field">
                    <div class="control has-icons-left">
                      <input class="input" type="password" placeholder="Senha" v-model="senha">
                      <span class="icon is-small is-left">
                        <i class="fi-xnluxl-lock"></i>
                      </span>
                    </div>
                  </div>
                  <!-- submit button -->
                  <div class="field">
                    <div class="control">
                      <button class="button is-primary is-fullwidth" :class="{'is-loading': enviando}" @click="sendForm">Login</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../api'
import userRules from '../config/userRules'

export default {
  name: 'Login',
  created () {
    if (this.user.rule !== this.userRules.VISITANT) {
      if (this.user.rule === this.userRules.ADMIN) {
        this.$router.push('/admin')
      } else if (this.user.rule === this.userRules.TEACHER) {
        this.$router.push('/p/t')
      } else if (this.user.rule === this.userRules.STUDENT) {
        this.$router.push('/p/s')
      }
    }
  },
  data () {
    return {
      email: '',
      senha: '',
      step: 0,
      rule: 'student',
      error: null,
      enviando: false
    }
  },
  computed: {
    ...mapState(['user']),
    userRules () {
      return userRules
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    redirect (to) {
      this.$router.push(to)
    },
    toForm (rule) {
      this.step = 1
      this.rule = rule
    },
    sendForm () {
      this.enviando = true
      if (this.rule === this.userRules.TEACHER) {
        this.sendTeacher()
      } else if (this.rule === this.userRules.STUDENT) {
        this.sendStudent()
      } else if (this.rule === this.userRules.RESPONSIBLE) {
        this.sendResponsible()
      } else {
        this.sendAdmin()
      }
    },
    sendAdmin () {
      api.post('/admins/auth', {
        email: this.email,
        password: this.senha
      }).then(res => {
        this.login({
          ...res.data,
          rule: this.userRules.ADMIN
        })

        this.redirect('/admin')
      }).catch(err => {
        this.enviando = false

        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    },
    sendTeacher () {
      api.post('/teachers/auth', {
        email: this.email,
        password: this.senha
      }).then(res => {
        this.login({
          ...res.data,
          rule: this.userRules.TEACHER
        })

        this.redirect('/p/t')
      }).catch(err => {
        this.enviando = false

        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    },
    sendStudent () {
      api.post('/students/auth', {
        email: this.email,
        password: this.senha
      }).then(res => {
        this.login({
          ...res.data,
          rule: this.userRules.STUDENT
        })

        this.$router.push('/t/r')
      }).catch(err => {
        this.enviando = false

        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    },
    sendResponsible () {
      api.post('/responsibles/auth', {
        email: this.email,
        password: this.senha
      }).then(res => {
        this.login({
          ...res.data,
          rule: this.userRules.RESPONSIBLE
        })

        this.redirect('/p/r')
      }).catch(err => {
        this.enviando = false

        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.hero
  .video
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  .logo
    display: block
    width: 100%
    max-width: 160px
    margin: 0 auto 25px auto
  .avatar
    margin: 0 auto
  .card
    position: relative
  .back-btn
    position: absolute
    top: 10px
    left: 10px
</style>
